import FF from "../../components/forms/FormField.module.css";

import { useState, type ReactNode } from "react";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { IconRadio } from "../../components/forms/IconRadio.tsx";
import { WRadioGroup } from "../../components/forms/WRadioGroup.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useGenerateUuid } from "../../utils/useGenerateUuid.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { OsVersionSelectCustom } from "./os/OsVersionSelect.tsx";
import { getDefaultOs, getResourceTypeImages, osIsWindows, type ComputeImageType } from "./os/os.ts";
import * as vmImagesQuery from "./vmImagesQuery.ts";
import { reinstallVmMutation, type VirtualMachineLoc } from "./vmQuery.ts";

interface ItemType {
    id: ComputeImageType;
    title: ReactNode;
    icon: string;
}

const types = [
    {
        id: "virtual_machine",
        title: "Virtual Machine",
        icon: "jp-virtual-machine-icon",
    },
    {
        id: "app_catalog",
        title: "App Catalog",
        icon: "jp-icon-appcatalog",
    },
] as const satisfies ItemType[];

export function ReinstallModal({ vm }: { vm: VirtualMachineLoc }) {
    const allImages = vmImagesQuery.useSuspense();
    const reinstallMutation = useStandardMutation(reinstallVmMutation);
    const requestUuid = useGenerateUuid(undefined);

    const { uuid, name, location, os_name } = vm ?? {};
    const defaultType = types[0].id;
    const [type, setType] = useState<ComputeImageType>(defaultType);
    const [os, setOs] = useState(getDefaultOsForType(defaultType));

    // TODO: Somehow share OS selection logic with VmCreate - not currently easily possible due to RHF being used there

    function onChangeType(type: ComputeImageType) {
        setType(type);

        // Reset OS when type changes
        setOs(getDefaultOsForType(type));
    }

    function getImages(type: ComputeImageType) {
        const images = getResourceTypeImages(allImages, type);
        return type === "virtual_machine" ? images.filter((img) => !osIsWindows(img.os_name)) : images;
    }

    function getDefaultOsForType(type: ComputeImageType) {
        return getDefaultOs(getImages(type));
    }

    return (
        <WModal button={<WModalButton label="Reinstall" icon="jp-retweet-icon" isDisabled={osIsWindows(os_name)} />}>
            <WModalContent
                title="Reinstall"
                label="Reinstall"
                modalAction={async () => {
                    await reinstallMutation.mutateAsync({ body: { uuid, ...os }, location, requestUuid });
                }}
            >
                <p>
                    Reinstalling "{name}" will destroy all data on the boot disk and set it to the initial clean OS
                    state. All snapshots and backups are preserved.
                </p>

                <WRadioGroup block label="Type" isRequired value={type} onChange={onChangeType}>
                    {types.map((item) => (
                        <IconRadio key={item.id} {...item} />
                    ))}
                </WRadioGroup>

                <FormField block wide label="OS" isRequired>
                    <div className={FF.FormFieldRadioGroup}>
                        <OsVersionSelectCustom items={getImages(type)} value={os} onChange={setOs} />
                    </div>
                </FormField>
            </WModalContent>
        </WModal>
    );
}
